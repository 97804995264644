

.featured {
    margin-top: 2%;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .featuredItem {
    flex: 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
  
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  @media only screen and (max-width: 600px) {
    .featured {
      margin-top: 2%;
      width: 90vw;
      display: block;
      justify-content: space-between;
    }
    .featuredItem {
      flex: 1;
      margin: 10px 20px;
      padding: 30px;
      border-radius: 10px;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    }
  }
  
  
  .featuredTitle{
    font-size: 30px;
    font-weight: 600;
    color: #000;
      float: right;
  }
  .w-40{
    width: 80%;
  }
  .rr{
    float: right;
    text-align: right;
    
  }
  .featuredMoneyContainer{
      margin: 10px 0px;
      display: flex;
      align-items: center;
      
  }
  
  .featuredMoney{
    float: right;
    text-align: right;
      font-size: 30px;
      font-weight: 600;
      color: #000;
  }
  
  .featuredMoneyRate{
      display: flex;
      align-items: center;
      margin-left: 20px;
  }
  .w100{
    width: 100%;
  }
  .featuredIcon{
      font-size: 14px;
      margin-left: 5px;
      color: green;
  }
  
  .featuredIcon.negative{
      color: red;
  }
  
  .featuredSub{
      font-size: 15px;
      color: gray;
      float: right; 
  }
  .container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }
  
  .col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
  }
  
  .col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
      width: 100%;
      margin-top: 0;
    }
  }
   /* CSS Libraries Used 
  
  *Animate.css by Daniel Eden.
  *FontAwesome 4.7.0
  *Typicons
  
  */
  @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.text-center{
 text-align: center;
}
.w-1000{
    width: 100vw;
 
}
.poo{
margin-bottom: 100px;
}
#customers td, #customers th {
    border: 1px solid #000;
    padding: 8px;
   
    text-align: center ;
  }
  h6{
    font-size: small;
  }
  .x400{
    margin-left:22%;
    justify-content: center;
    width: 800px;
  }
.text-center1{
    width: 100%;
    height: 100%;
    border: none;
    text-align: center;
   }
html {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    color: #fff;
}
.imglibya{
    justify-content: center;
    margin-left: auto;
    margin-right: auto;


  width: 600px;
  height: 95vh;
  background-image: url( '../public/country.png' );
  background-repeat: no-repeat;
  background-size: 600px;
}
.bg-100{
    margin-top: 5%;
    margin-right: 1%;
}
.bg-20{
    margin-top: 2.0%;
    margin-right: 1%;
}
#fullWidth{
 min-width: 40vw;
}
.center1 {

    display: flex;
    margin-left: auto;
    margin-right: auto;

  }
.m6{
    margin-top: 15vh;
    margin-left: 10%;
    margin-right: 10%;
    
}
body {
    min-height: 100vh;
    background-color: #EFF6FC;
}
.ml150{
    margin-left: 10px;
}
.flexsp{
    display: flex;
    justify-content: start;

     
}
.flexsp1{
  display: flex;
  justify-content: start;
 width: 300px;
 margin-top: 20px;
   
}
.App {
  display: flex;
  flex-direction: column;
  direction: rtl;

  justify-content: center;
  align-items: center;
  min-height: 100vh; 
  padding: 1rem 0.5rem;
}
.C1111{
    width: 520px;
}
.c1{
    margin-left: 20%;
}
section{
    width: 100%;
    max-width: 520px;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 25px;
  
}
.xxx{
   margin-left:auto ;
   justify-content: center;
   
}
/* form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
} */

a, a:visited {
    color: #fff;
}
td{
    color: #000;
}
span{
    color: #000;
}
h1{
    font: 2em sans-serif;
    color: #000;
}

h5 {
    color: #000;
    text-align: center; 
    text-decoration: none;
}
a{
    color: #000;
    text-align: center; 
    text-decoration: none;
}
label,
button {
 
  color: #000;
}

button {
  padding: 0.5rem;
}

li {
    list-style-type: none;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}

.flexGrow {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}


.persistCheck {
    font-size: 0.75rem;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.persistCheck label {
    margin: 0;
}

[type="checkbox"] {
    height: 20px;
    width: 20px;
    margin: 0 5px 2px 2px;
}

button{
    background-color: #EFF6FC;
}
.Home{
    width: 100%;
    width: 920px;
   min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: #ffffff;

  
}
.C0{
    width: 1000px;
    display: flex;
    justify-content: center;
   margin-left: auto;
   margin-right: auto;
}
.C{
font-size: 40px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  justify-content: center;
    text-align: center;
    background-color: red;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    color: #fff;
}
.R{
    color: red; 
    display: flex;
    margin-left: auto;
    margin-right: auto;
  justify-content: center;
    text-align: center;
}
.R22{
    font: 1em sans-serif;
    font-size: 35px;
       color: red; 
       display: flex;
       margin-left: auto;
       margin-right: auto;
     justify-content: center;
       text-align: center;
       margin-top: 70px;
   
   }
.R1{
 font: 1em sans-serif;
 font-size: 40px;
    color: red; 
    display: flex;
    margin-left: auto;
    margin-right: auto;
  justify-content: center;
    text-align: center;
}
.l{
    text-align: left;
    float: left;   
}
.R2{
   text-align: right;
   float: right;
       color: red; 
      
   }
.label{
    float: right;
}
.label2{
    padding-right: 20px;
    float: right;
    text-align: right;
    width: auto;
  
}
.input{
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    padding: 0.25rem;
    height:35px ;
    width: 300px;
    border-radius:10px ;
}
.input1{
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    padding: 0.25rem;
    height:37px ;
    width: 300px;
    border-radius:10px ;
}
.input2{
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    padding: 0.25rem;
    height:37px ;
    width: 400px;
    border-radius:10px ;
}
#name{
    color: #000;
}
.rg1{
    float:right ;
}
.basic-multi-select{
 color: #000;
 border-radius: 25px;
}
select{
    border: #DFDFDF 2px solid;
    width: 100px;
    height:30px ;
    border-radius: 5px;
    width: 200px;
}  
.mt-5{
    margin-top: 30px;
    margin-right: 30px;
} 
.mx-1{
    margin-right: 20px;
}
.flex{
    display: flex;
justify-content: space-between;
}
.flex00{
    display: flex;
margin-bottom: 2.77px;
}
.flex000{
    display: flex;
margin-bottom: 2.77px;
width: 50%;
}
.flex0{
    display: flex;
    /* justify-content: space-around; */
}
.border{
    border-top: 3px dashed ;
    width: 200px;
}
.border1{
    border-top: 3px solid ;
    width: 200px;
}
.button{
    display: flex;
    margin-left: auto;
    margin-right: auto;
  justify-content: center;
    text-align: center;
    width: 400px;
}
.c0{
   
    margin-left: 62%;
    justify-content: left;
    float: right;
    text-align: right;
    width: 200px;
    
}
.xc{
    background-color: #F8ADA1;
    display: flex;
    margin-left: 100px;
    margin-right: auto;
    justify-content: center;
}
.label1{
   background-color: #F8ADA1;
   border-radius: 15px ;
  text-align: center;
   width: 70px;
   margin-right: 10px ;
}
.Flex1{
    display: flex;
    text-align:right;
}
.text{
    text-align: left;
    float: left;
  
    padding: 0;
   
    margin-left: 10px;
}
.box{
width: 200px;
height: 170px;
background-color: #EFF6FC;
border-radius: 25px ;
}
.center1{


    justify-content:space-around;
    margin-left: auto;
    margin-right: auto;
}
.center{

    display: flex;
       justify-content: center;
       margin-left: auto;
       margin-right: auto;
   }
.kk{
    margin-left: 15px;
}
.h3{
    color:black;
    text-emphasis: none;
    text-align: center;
    justify-content: center;
}
@media print {

 
    table th, table td  {
        border:1px solid black;
        padding: 5px;
        font-size: 12px;
        text-align: center;
        border-collapse: none;
    }
}
 
.box300{
    width: 100%;
    height: 170px;
border: 1px solid #000;
    border-radius: 25px ;
}
.line{
    border: 1px solid #000;
    width: 100px;
}
.spinner-container{
 
    margin-top: 40vh;
    display: flex;
    margin-left: 45vw;
    margin-right: auto;
    justify-content: center;
    position: fixed;
}
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 100px;
    height: 100px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid blue; /* Black */
    border-radius: 100%;
    animation: spinner 1.5s linear infinite;
  }
.b2{
    margin-right:11%;}
    #customers {
        color: blue;
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      #customer {
        color: blue;
        font-family: Arial, Helvetica, sans-serif;
     
        width: 100%;
      }
     
      #customers tr:nth-child(even){background-color: #f2f2f2;}
      
      #customers tr:hover {background-color: #ddd;}
      #customer tr:nth-child(even){background-color: #f2f2f2;}
      
      #customer tr:hover {background-color: #ddd;}
      #customers th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        background-color:  #1976D2;
        color: black;
      }
      #customer th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
     
        color: black;
      }
      .W100{
        width: 100vw;
       
      }
 .MuiDataGrid-panelContent {
   
      background-color: white;
     
      
      }
.MuiPaper-root{
    background-color: none;
}
   
.input0,button{
    border: #DFDFDF 2px solid;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    padding: 0.25rem;
    border-radius: 0.5rem;
}
.bg{
    margin-top: 25vh;
background-color: rgba(0, 0, 0, 0.701);
width: 40vw;
height: 40vh;
border-radius: 25px;
}
.bg1{
    margin-top: 25vh;
background-color: rgba(0, 0, 0, 0.701);
width: 40vw;
height: 40vh;
border-radius: 25px;
}
.ml{
    margin-left: 20px;
margin-top: 20px;
}
.xl{
   background-color: #F8ADA1;

}

.ml1{
    margin-top: 20px;
    margin-left: 190px;

}
.centermt{
    margin-top: 40vh;
  
}
.slader{
    max-width: 350px;
     background-color: #1976D2;
     height: 100vh;
 }
 .img{
    display: flex;
    margin: auto;
 width: 100px;
     height: 100px;
 }
 .Logo-img1{
     margin-top: 100px;
     width: 100%;
     height: 100%;
 }
 .buttonitems{
    
     text-align: center;
     width: 400px;
     /* height: 100%; */
     
   
     
 }
 .Active{
     background-color: #003cff;
     border: none;
     padding: 20px 20px;
     margin: 10px;
     width: 250px;
     color: white;
     font-size: 120%;
     border-radius: 20px;
     text-align: end;
 }
 .NONActive{
     background-color: #1976D2;
     border: none;
     padding: 20px 20px;
     margin: 5px;
     width: 250px;
     color: white;
     font-size: 120%;
     text-align: end;
 }
 .Homeadmin{
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
}
.w-100{
    margin: 2%; 
    margin-left: 2% ;;
}
.flexadmin{
    display: flex;
    justify-content: space-between;
}
.Table-centeradmin{
width: 90%;

background-color: white;
/* border-radius: 25px; */
}
.sH {
    position: sticky;
    top: 0;
  }
  th {
    position: sticky;
    top: 0;
  }
  .kiki{

  /* view 5 item in one row ? */
  display: flex;
    grid-template-columns: repeat(5, 1fr);  
    grid-gap: 10px;
  
    

    
  }

    @media print{
        .floating-print-button{
            display: none;
        }
        .footer{
            position: relative;
            bottom: 0;
            left: 0;
            
    }
    th {
        position:static;
        
      }
    body {-webkit-print-color-adjust: exact;}
    }
    .floating-print-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 999;
      }
      
      .floating-print-button button {
        display: block;
        width: 100px;
        height: 40px;
        border: none;
        border-radius: 20px;
        background-color: #007bff;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0,0,0,0.4);
        transition: background-color 0.2s ease;
      }
      
      .floating-print-button1 button:hover {
        background-color: #0056b3;
      }
      .floating-print-button1 {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 999;
      }
      
      .floating-print-button1 button {
        display: block;
        width: 200px;
        height: 40px;
        border: none;
        border-radius: 20px;
        background-color: #007bff;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0,0,0,0.4);
        transition: background-color 0.2s ease;
      }
      
      .floating-print-button1 button:hover {
        background-color: #0056b3;
      }
      
.form-inline {  
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  
  .form-inline label {
    margin: 5px 10px 5px 0;
  }
  
  .form-inline input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
  .form-inline button {
    padding: 10px 20px;
    background-color: dodgerblue;
    border: 1px solid #ddd;
    color: white;
    cursor: pointer;
  }
  
  .form-inline button:hover {
    background-color: royalblue;
  }
  
  @media (max-width: 800px) {
    .form-inline input {
      margin: 10px 0;
    }
    
    .form-inline {
      flex-direction: column;
      align-items: stretch;
    }
  }